<template>
  <home />
</template>

<script>
import Home from "./Home";
export default {
  name: "Contact.vue",
  components: { Home },
  mounted() {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style scoped></style>
